<!--
 * @FilePath: \enery-data-v\src\App.vue
 * @Description: 
-->
<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
// import Index from './page/Index.vue'
import Vue from 'vue'
import Cookie from "js-cookie";
export default {
	name: 'App',
	// components: {
	// 	Index,
	// },
	mounted(){
		localStorage.setItem("token",Cookie.get("token"))
	}
}
</script>

<style lang="less">
@import '~@/components/index.less';
*{
  scrollbar-color: #0f326b rgba(97, 184, 179, 0.1); /* 滑块颜色  滚动条背景颜色 */
  scrollbar-width: thin;
}
 /* 滚动条宽度有三种：thin、auto、none */
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
//   margin-left: 20px;
  height: 3px;
  cursor: pointer !important;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
  background: #0f326b;
  cursor: pointer !important;
}
::-webkit-scrollbar-thumb:hover {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
  background: #6781B3;
  cursor: pointer !important;
}
// ::-webkit-scrollbar-track {
//   /*滚动条里面轨道*/
//   box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
//   border-radius: 10px;
//   // background: #ededed;
// }
</style>
