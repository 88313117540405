/*
 * @FilePath: \web\src\router\index.js
 * @Description:
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'street',
		component: () => import('@/page/street.vue'),
	},
	// {
	// 	path: '/community',
	// 	name: 'community',
	// 	component: () => import('@/page/community.vue'),
	// }
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
})

export default router
